/* global Component, _ */
class FavouriteOrderActionsComponent extends Component {

    static name() {
        return "FavouriteOrderActionsComponent";
    }

    static componentName() {
        return "FavouriteOrderActionsComponent";
    }

    getProps() {
        return  ['data', 'index', 'column'];
    }

    getMethods() {
        return {
            buyOrder:this.buyOrder,
            toggleFavouriteOrder:this.toggleFavouriteOrder,
            openDetail:this.openDetail,
            addItemToCar: this.addItemToCar,
            getMaxStockByCode: this.getMaxStockByCode,
            transformInfo: this.transformInfo
        };
    }

    transformInfo(favObject) {
        this.message = null;
        if (favObject &&  favObject[1]) {
            let notFoundItems = 0;
            let items = [];
            for (let [index, item] of favObject[1].entries()) {
                const storeItem = _.cloneDeep(this.$store.getters.getItemByCode(item[0]));
                if(storeItem) {
                    storeItem.index = index;
                    storeItem.carQyt = item[1];
                    items.push(storeItem);
                } else {
                    notFoundItems += 1;
                }
            }
            if(notFoundItems > 0)
                this.message = [`{{$1}} item was omitted from this order`, notFoundItems];

            return items;
        }
        return [];
    }

    addItemToCar(item, reloadCard = false) {
        if(isNaN(item.carQyt) || parseInt(item.carQyt) == 0) {
            this.alertShowMessage(`Incorrent Qty Value`);
            return;
        }
        const storeItem = _.cloneDeep(this.$store.getters.getItemByCode(item.Code));
        if(this.controlStock) {
            let itemInCar = this.$store.getters.getItemCar.filter(x => x.id == item.Code)[0];
            const maxQty = this.getMaxStockByCode(item.Code);
            if (itemInCar) {
                if (parseInt(itemInCar.cant) + parseInt(item.carQyt) > maxQty) {
                    if(maxQty === 0)
                        this.alertShowMessage("ITEM_STOCK_ZERO");
                    else
                        this.alertShowMessage(["The article qty can't be bigger than {{$1}}",maxQty]);
                    return;
                }
            }
            else{
                if (parseInt(item.carQyt) > maxQty) {
                    if(maxQty === 0)
                        this.alertShowMessage("ITEM_STOCK_ZERO");
                    else
                        this.alertShowMessage(["The article qty can't be bigger than {{$1}}",maxQty]);
                    return;
                }
            }
        }

        if(reloadCard)
            this.$store.dispatch('ItemComponentAddToCar', storeItem);
        else
            this.$store.commit('updateItemToCar', storeItem);
    }

    getMaxStockByCode(itemCode){
        let giftCardItem = this.$store.getters.getGiftCardItem;
        if(this.$store.getters.getSetting.ControlStockForSales &&  !(giftCardItem && giftCardItem.fields.Code == itemCode)) {
            let itemInStore = this.$store.getters.getItemByCode(itemCode);
            const qtyInCar = this.$store.getters.getItemAmountInCar(itemCode);
            if(itemInStore.Reserved)
                return itemInStore.Qty - itemInStore.Reserved - qtyInCar;
            return itemInStore.Qty - qtyInCar;
        }
        return this.$store.getters.getMaxQtyItem - this.$store.getters.getItemQtyCar(itemCode);
    }

    buyOrder() {
        const items = this.transformInfo(this.$store.getters.getFavouriteOrderByName(this.data.Name));
        if(items.length > 1)
            items.slice(0, -1).forEach(x=>this.addItemToCar(x));
        if(items.length > 0)
            items.slice(-1).forEach(x=>this.addItemToCar(x, true));
    }

    toggleFavouriteOrder(){
        let FV = this.$store.getters.getFavouriteOrderByName(this.data.Name);
        let FavOrdeToDelete = {
            name: FV[0],
            items: FV[1],
            OrderSerNr: FV[2],
            from: 'so-items'
        };
        this.$store.dispatch('toggleFavouriteOrder', FavOrdeToDelete);

    }

    openDetail(){
        this.$store.dispatch('favOrderDetail', this.data.Name);
    }

    getTemplate() {
        return`<div :key="'FavouriteOrderActions-'+index" class="custom-actions" >
                   <a :key="'actions-showFavouriteOrderDetail-'+index"  :title="tr('View')" data-toggle="modal" data-target="#favOrderDetailModal"  @click="openDetail">
                     <i class="fas fa-file-alt"></i>
                   </a>
                  <a :key="'actions-sendToCarFavouriteOrder-'+index"  :title="tr('Checkout')" @click="buyOrder">
                    <i class="fa fa-shopping-cart"></i>
                  </a>
                  <a :key="'actions-removeFavouriteOrderDetail-'+index"  :title="tr('Remove from Favorite')"  @click="toggleFavouriteOrder">
                    <i class="fas fa-trash"></i>
                  </a>
               </div>`;
    }
}

FavouriteOrderActionsComponent.registerComponent();